<template>
  <div id="user.components.resetPasswordButton">
    <b-button variant="success" size="lg" :disabled="!active" @click="clicked()"
      ><i class="fas fa-key"></i>
      {{ $t("user.form.password.buttons.reset") }}</b-button
    >
  </div>
</template>

<script>
export default {
  name: "user.components.resetPasswordButton",
  props: ['active'],
  methods: {
    clicked() {
      this.$emit('clicked')
    },
  },
};
</script>

<style>
</style>