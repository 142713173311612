<template>
  <div id="user.activate.resend">
    <b-row class="m-0 p-4">
      <b-col md="3"></b-col>
      <b-col md="6">
        <b-card>
          <b-row v-if="reset === false">
            <b-col md="12">
              <b-alert show variant="danger" class="text-center">
                Failed to reset your password <br>
                Try <b-link :to="{name: 'login'}">Login</b-link> in to see if it was already changed <br>
                If this is unsuccesfull please contact <b-link :to="{name: 'feedback'}">use</b-link>
              </b-alert>
            </b-col>
          </b-row>
          <b-row v-else-if="reset === true">
            <b-col md="12">
              <b-alert show variant="success" class="text-center">
                Password was succesfully reset <br>
                You can now <b-link :to="{name: 'login'}">Login</b-link>
              </b-alert>
            </b-col>
          </b-row>
          <password />
          <password-repeat />
          <b-row>
            <b-col class="d-flex mt-2 justify-content-center"
              ><reset-password-button :active="valid" v-on:clicked="resetPassword()" />
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <b-col md="3"></b-col>
    </b-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import password from "../form/password/password";
import passwordRepeat from "../form/password/passwordRepeat";
import resetPasswordButton from "../components/resetPasswordButton";
import logic from "@/scripts";
export default {
  name: "user.activate.resend",
  components: {
    password,
    passwordRepeat,
    resetPasswordButton
  },
  computed: {
    ...mapGetters({
      form: "getPasswordResetForm",
      reset: "getPasswordReset",
    }),
    valid() {
      return logic.input.user.password.reset.validate(this.form.password, this.form.passwordRepeat);
    },
  },
  methods: {
    resetPassword() {
      const uid = this.$route.params.uid;
      const token = this.$route.params.token;
      this.$store.dispatch("resetPassword", {uid: uid, token: token})
    }
  }
};
</script>

<style>
</style>