<template>
  <div id="user.form.register.password">
    <b-form-group class="mb-2" id="name-group">
      <label for="password-live">{{$t('user.form.password.password.label')}}:</label>
      <b-input-group>
        <b-form-input
          id="password-live"
          type="password"
          v-model="$v.password.$model"
          :state="validateState('password')"
          aria-describedby="password-feedback"
        ></b-form-input>
      </b-input-group>
      <div v-if="$v.password.$anyError" id="password-feedback">
        <small
          v-if="!$v.password.required"
          class="form-text text-danger"
        >{{$t('user.form.password.password.required')}}</small>
        <small
          v-if="!$v.password.minLength"
          class="form-text text-danger"
        >{{$t('user.form.password.password.minLength', { min: getLowerBoundary() })}}</small>
        <small
          v-if="!$v.password.maxLength"
          class="form-text text-danger"
        >{{$t('user.form.password.password.maxLength', { max: getUpperBoundary() })}}</small>
        <small
          v-if="!$v.password.minCharacter"
          class="form-text text-danger"
        >{{$t('user.form.register.password.minCharacter', { min: getLowerBoundaryChar() })}}</small>
        <small
          v-if="!$v.password.minCapital"
          class="form-text text-danger"
        >{{$t('user.form.register.password.minCapital', { min: getLowerBoundaryCapital() })}}</small>
        <small
          v-if="!$v.password.minNumber"
          class="form-text text-danger"
        >{{$t('user.form.register.password.minNumber', { min: getLowerBoundaryNumber() })}}</small>
        <small
          v-if="!$v.password.minSpecial"
          class="form-text text-danger"
        >{{$t('user.form.register.password.minSpecial', { min: getLowerBoundarySpecial() })}}</small>
      </div>
    </b-form-group>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import { mapGetters } from 'vuex';
import logic from '@/scripts';
export default {
  name: "user.form.password.password",
  computed: {
    ...mapGetters({
      form: 'getPasswordResetForm'
    }),
    password: {
      get() {
        return this.form.password
      },
      set(value) {
        this.$store.commit("setPasswordResetFormPassword", value)
      }
    },
  },
  
  validations: {
    password: {
      required,
      minLength() {
        return (
          logic.input.user.password.reset.password.minLength(this.form.password)
        );
      },
      maxLength() {
        return (
          logic.input.user.password.reset.password.maxLength(this.form.password)
        );
      },
      minCharacter() {
        return (
          logic.input.user.register.password.minCharacter(this.form.password)
        )
      },
      minCapital() {
        return (
          logic.input.user.register.password.minCapital(this.form.password)
        )
      },
      minNumber() {
        return (
          logic.input.user.register.password.minNumber(this.form.password)
        )
      },
      minSpecial() {
        return (
          logic.input.user.register.password.minSpecial(this.form.password)
        )
      }
    },
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v[name];
      return $dirty ? !$error : null;
    },
    getLowerBoundary() {
      return logic.input.user.register.password.min
    },
    getUpperBoundary() {
      return logic.input.user.register.password.max
    },
    getLowerBoundaryChar() {
      return logic.input.user.register.password.minChar
    },
    getLowerBoundaryCapital() {
      return logic.input.user.register.password.minCap
    },
    getLowerBoundaryNumber() {
      return logic.input.user.register.password.minNum
    },
    getLowerBoundarySpecial() {
      return logic.input.user.register.password.minSpec
    },
  }
};
</script>

<style>
</style>